<template>
    <card-table-search title="Liste des emprunts"
                       :items="empruntsFiltered"
                       :fields="fields"
                       :actions="actions"
                       :custom-rendered="['utilisateur', 'jdrs', 'jeux', 'livres', 'valide']">
        <template #body_top>
            <b-row class="mb-3">
                <b-col xl="10" offset-xl="1">
                    <b-row>
                        <b-col lg="2" class="filter-text">
                            <h3>Filtre :</h3>
                        </b-col>
                        <b-col lg="10">
                            <multi-select v-model="filter"
                                          :options="filterOptions"
                                          :multiple="false"
                                          :close-on-select="true"
                                          track-by="value"
                                          :clear-on-select="true"
                                          :preserve-search="false"
                                          label="text"/>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </template>

        <template #utilisateur="{ row }">
            <user-name :user="row.item.utilisateur"/>
        </template>
        <template #jdrs="{ row }">
            <div class="large-column-table" v-b-popover.hover.left="row.value">
                {{ row.value }}
            </div>
        </template>
        <template #jeux="{ row }">
            <div class="large-column-table" v-b-popover.hover.left="row.value">
                {{ row.value }}
            </div>
        </template>
        <template #livres="{ row }">
            <div class="large-column-table" v-b-popover.hover.left="row.value">
                {{ row.value }}
            </div>
        </template>
        <template #valide="{ row }">
            <b-badge :variant="getBadgeColorStatut(row.item)">
                {{ getTextStatut(row.item) }}
            </b-badge>
        </template>
    </card-table-search>
</template>

<script>
    const MultiSelect            = () => import('@/components/MultiSelect');
    const CardTableSearch        = () => import('@/components/CardTableSearch');
    const UserName               = () => import('@/components/UserName');
    const CommentValidationModal = () => import('@/components/modals/CommentValidationModal');

    import {
        STATUT_EMPRUNT_CREE,
        STATUT_OBJETS_PARTIS,
        STATUT_OBJETS_RENDUS
    }                           from '@/util/emprunt';
    import {textUsername}       from '@/util/text';
    import {isCluji}            from '@/util/user';
    import {apiPath}            from '@/util/http';
    import alert                from '@/util/alert';
    import demande              from '@/util/demande';
    import {updateBadgesBureau} from '@/util/badge';
    import empruntsMixin        from '@/mixin/empruntsMixin';

    export default {
        name: "AdminEmprunts",
        components: {MultiSelect, CardTableSearch, UserName},
        mixins: [empruntsMixin],
        data() {
            return {
                filter: {
                    text: 'Tous les emprunts',
                    value: null
                },
                filterOptions: [
                    {
                        text: 'Tous les emprunts',
                        value: null
                    },
                    {
                        text: 'Emprunt créé',
                        value: STATUT_EMPRUNT_CREE
                    },
                    {
                        text: 'Objets partis',
                        value: STATUT_OBJETS_PARTIS
                    },
                    {
                        text: 'Objets rendus',
                        value: STATUT_OBJETS_RENDUS
                    }
                ],
                emprunts: [],
                fields: [
                    {
                        key: 'utilisateur',
                        label: 'Utilisateur',
                        sortable: true,
                        formatter: textUsername
                    },
                    {
                        key: 'dateDebut',
                        label: 'Dates',
                        sortable: true,
                        formatter: (value, key, item) => demande.formatDates(item)
                    },
                    {
                        key: 'jdrs',
                        label: 'JdR',
                        formatter: value => value.map(({nom}) => nom).join(', ')
                    },
                    {
                        key: 'jeux',
                        label: 'Jeux',
                        formatter: value => value.map(({nom}) => nom).join(', ')
                    },
                    {
                        key: 'livres',
                        label: 'Livres',
                        formatter: value => value.map(({nom}) => nom).join(', ')
                    },
                    {
                        key: 'valide',
                        label: 'Statut',
                        sortable: true,
                        formatter: (value, key, item) => demande.getTextStatut(item)
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_accept',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: 'Accepter',
                        display: ({item}) => item.valide === null,
                        handler: ({item}) => this.confirmEmprunt(item, true)
                    },
                    {
                        key: 'action_refuse',
                        color: () => 'danger',
                        icon: 'times',
                        tooltip: 'Refuser',
                        display: ({item}) => item.valide === null,
                        handler: ({item}) => this.confirmEmprunt(item, false)
                    },
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Détail',
                        handler: ({item}) => this.displayEmpruntModal(item)
                    },
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editEmpruntModal(item)
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: 'Supprimer',
                        handler: ({item}) => this.deleteEmprunt(item)
                    }
                ]
            }
        },
        computed: {
            empruntsFiltered() {
                return typeof this.filter.value === 'number' ? this.emprunts.filter(({statut}) => statut === this.filter.value) : this.emprunts;
            }
        },
        methods: {
            ...demande,
            isCluji,
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_all_emprunts_admin', {limit: 100}))
                    .then(response => this.emprunts = this.addRowVariant(demande.momentDates(response.data)))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des emprunts'))
                    .finally(alert.stopLoading);
            },
            confirmEmprunt(emprunt, valid) {
                this.$store.dispatch('modal/create', {
                    component: CommentValidationModal,
                    props: {
                        title: (valid ? 'Validation' : 'Refus') + " de l'emprunt",
                        commentaireUtilisateur: emprunt.commentaireUtilisateur,
                        callback: (commentaire, notifyDiscord) => this.axios
                            .post(
                                apiPath('confirm_emprunt_admin', {emprunt: emprunt.id}),
                                {valid: valid, commentaire: commentaire, notify_discord: notifyDiscord}
                            )
                            .then(() => {
                                this.$toaster.success('Emprunt ' + (valid ? 'validé' : 'refusé') + ' avec succès');
                                this.loadData();
                                updateBadgesBureau();
                            })
                            .catch(() => this.$toaster.error("Impossible de modifier l'emprunt"))
                    }
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .filter-text, .filter-text h3 {
        text-align: right;
        line-height: 40px;
    }

    .large-column-table {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
        overflow: hidden;
    }
</style>
